.counter {
    padding: 8px;
    border-radius: 5px;
    line-height: 1;
    margin-bottom: 5px;
    border: none;
    background-color: transparent;
    font-size: 2rem;
    color: white;
    font-weight: 300;

    &--total {
        background-color: #0072ba;
    }

    &--correct {
        background-color: #2b8237;
    }

    &--incorrect {
        background-color: #c83e3e;
    }

}